<template>
  <div class="container" id="NumberVerification">
    <div class="verificationContainer">
      <div class="verifyNumber font-size-xl">
        {{ $t("verify_phone.mobile_number") }}
      </div>
      <div class="bottomContainer">
        <div class="noticeText font-size-md">
          {{ $t("verify_phone.text_phone") }}
        </div>
        <div class="inputBox pt-3">
          <NumberPrefixes
            name="number"
            v-model="phoneNumber"
            :number="phoneNumber"
          />
        </div>
        <div class="buttonBox" :class="{ loadingStyle: loading }">
          <button class="btn btn-primary buttonStyle" @click="sendNumberPhone">
            {{ $t("verify_phone.send_code") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberPrefixes from "@/components/NumberPrefixies";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NumberPhone",
  components: { NumberPrefixes },
  data() {
    return {
      phoneNumber: "",
      loading: false
    };
  },
  methods: {
    ...mapActions(['setErrors']),
    async numberPhone() {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("verify_number");
        window.axios.post(url, { phone: this.phoneNumber }).then(
          response => {
            resolve(response.data);
          },
          error => {
            this.setErrors([error.response.data.errors]);
            reject(error.response.data.errors);
          }
        );
      });
    },

    async sendNumberPhone() {
      this.loading = true;
      try {
        await this.numberPhone();
        await this.$router.push({
          path: `/verifyPhone/verifyNewNumber/` + this.phoneNumber
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters(["errors"])
  },
  mounted() {
    this.phoneNumber = this.$route.params.number;
  }
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;
  display: flex;
  justify-content: center;

  .verificationContainer {
    padding-top: 90px;
    align-items: center;
    flex-direction: column;
    display: flex;

    .verifyNumber {
      font-weight: bold;
      color: map-get($colors, "black");
      text-align: center;
    }
    .bottomContainer {
      max-width: 310px;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      .noticeText {
        max-width: 250px;
        margin-top: 10px;
        font-weight: 400;
        color: #6f8c95;
        text-align: center;
      }
      .inputBox {
        width: 100%;
        margin: 25px 0;
      }

      .buttonBox {
        width: 100%;
        position: relative;
      }
      .loadingStyle {
        opacity: 0.5;
      }
      .buttonStyle {
        height: 48px;
        width: 100%;
      }
    }
  }
}
</style>
