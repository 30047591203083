<template>
  <div class="inputContainer">
    <!--  Label  -->
    <div class="labelStyle">
      {{ $t("personal_info.number") }}
    </div>

    <!--  Prefixes  -->
    <select2
        v-model="prefixNumber"
        class="select2-prefix"
        :options="prefixes"
        :settings="{
        templateResult:select2template,
        templateSelection:select2template,
        dropdownCssClass: 'select2-prefix',
        placeholder: prefixNumber,
      }"
        @select="prefixChanged($event)"
    />

    <!--  Number  -->
    <Field
        :placeholder="phoneNumberPlaceHolder"
        v-model="phoneNumberPlaceHolder"
        class="inputNumber"
        type="text"
        :name="name"
        :rules="rules"
        @keyup="updateValue"
    />

    <validation-error :name="name" class="errorMessage"/>
    <ErrorMessage :name="name" class="errorMessage"/>
  </div>
</template>

<script>
import {numberPrefixes} from "@/includes/numberPrefixes.js";
import ValidationError from "@/components/ValidationError.vue";
import {Field, ErrorMessage} from "vee-validate";
import Select2 from "vue3-select2-component";

export default {
  name: "NumberPrefixes",
  components: {
    ValidationError,
    Field,
    ErrorMessage,
    Select2
  },
  data() {
    return {
      phoneNumberPlaceHolder: "",
      prefixNumber: "",
    };
  },
  computed: {
    prefixes() {
      return numberPrefixes.map(prefix => {
        prefix.id = "+" + prefix.phoneCode;
        prefix.text = "+" + prefix.phoneCode;

        //Check if prefix is selected
        if (this.prefixNumber.length !== 0) {
          if (prefix.id === this.prefixNumber) {
            prefix.selected = true;
          }
        }

        return prefix;
      })
    }
  },
  props: {
    modelValue: {
      type: [String, Number]
    },
    name: {
      type: String,
      default: ""
    },
    rules: {},
    prefixPlaceholder: {
      type: String,
      default: ""
    },
    number: {
      default: null
    }
  },
  methods: {
    //Format Select2
    select2template(prefix) {
      if (!prefix.id) {
        return prefix.text;
      }
      //Generate Dom Template
      let dom = document.createElement('div');
      dom.classList.add("prefix");
      dom.innerHTML = '<span> ' + prefix.map + ' </span> +' + prefix.phoneCode + '';
      return dom;
    },

    // On prefix change
    prefixChanged() {
      this.$emit(
          "update:modelValue",
          this.prefixNumber + " " + this.phoneNumberPlaceHolder
      );
    },
    //On input number value change
    updateValue(event) {
      //Filter number
      let inputValue = parseInt(event.target.value);
      if(isNaN(inputValue)){
        inputValue = "";
      }

      //Emit the change
      this.phoneNumberPlaceHolder = inputValue;
      this.$emit(
          "update:modelValue",
          this.prefixNumber + " " + inputValue
      );
    }
  },
  watch: {
    //When number is supplied, format the number
    // Extract number and prefix from it
    number() {
      if (this.number !== null) {
        this.phoneNumberPlaceHolder = this.number.split(" ")[1];
        this.prefixNumber = this.number.split(" ")[0];
      } else {
        //If number is not present, then default prefix is the first prefix
        this.prefixNumber = "+" + numberPrefixes[0].phoneCode
      }
    }
  }
};
</script>

<style lang="scss">
.inputContainer {
  z-index: 10;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: 1px solid map-get($colors, "gray");


  .labelStyle {
    position: absolute;
    bottom: 26px;
    font-size: 14px;
    z-index: -1;
    transition: ease-in-out 0.2s;
    color: map-get($colors, "secondary");
    font-weight: 400;
  }

  .select2-prefix {
    .select2-container {
      width: 115px;

      .selection {
        .select2-selection {
          border-bottom: 0;
        }
      }
    }
  }

  .inputNumber {
    width: 100%;
    height: 30px;
    line-height: 16px;
    border: none;
    margin-left: 10px;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .errorMessage {
    color: red;
    left: 0;
  }
}
</style>
